import VatomInc, { RequestService } from "./index";
import {
	Business,
	BusinessPageConfig,
	BusinessStats,
	BusinessRewardsSettings,
	IsBusinessAdmin,
} from "reducers/entitiesReducers";
import { Invite } from "./account";
import { Client } from "./clients"
import { DnsRecord } from "reducers/businessDomainsReducer";

export interface BusinessUpdate {
	displayName?: string;
	url?: string;
	location?: string;
	logoSrc?: string;
	ethAccount?: string;
	ownerOperated?: boolean;
	defaultCampaignId?: string;
	paymentMethod?: string; // This can only be updated by system admins
	owner?: string; // This can only be updated by system admins
	coin?: BusinessRewardsSettings;
}

export interface Option {
	value: string;
	label: string;
}

export interface WalletService{
	id?:string,
	name:string
}

export interface WalletServiceResponse{
	value?:string,
	label:string
}

export interface Domain {
	businessId: string;
	createdAt: string;
	dnsRecordName: string;
	dnsRecordType: "cname" | "txt";
	dnsRecordValue: string;
	domains: Array<Domain>;
	isEnabled: boolean;
	kongRouteId: any; 
	logId: string;
	nextVerifyAt: string;
	status: string;
	type: string;
	updatedAt: string;
	value: string;
	isCustomized: boolean;
	rootDomain: string;
}

export interface DefaultDomain {
	value: string;
	dns: DnsRecord | null,
	isCustomized: boolean;
	status?: string;
}

export default class VatomIncBusinesses {
	constructor(private varius: typeof VatomInc) {}

	public async getBusiness(businessName: string): Promise<Business> {
		const res = await this.varius.get({
			path: `/b/${businessName}`,
			isAuthed: true,
		});

		// todo: check `status` in body
		return res.body;
	}

	public async isBusinessAdmin(businessName: string, userId: string): Promise<IsBusinessAdmin> {
		const res = await this.varius.get({
			path: `/b/${businessName}/users/${userId}/amIBusinessAdmin`,
			isAuthed: true,
		});

		// todo: check `status` in body
		return res.body;
	}

	public async getStats(businessId: string): Promise<BusinessStats> {
		const res = await this.varius.get({
			path: `/b/${businessId}/stats`,
			isAuthed: true,
		});

		// todo: check `status` in body
		return res.body;
	}

	public async updateBusiness(businessId: string, update: BusinessUpdate): Promise<Business> {
		const res = await this.varius.patch({
			path: `/b/${businessId}`,
			isAuthed: true,
			body: update,
		});

		// todo: check `status` in body
		return res.body;
	}

	public async patchBusinessPageConfig(
		businessId: string,
		pageConfig: BusinessPageConfig | null
	): Promise<Business> {
		const res = await this.varius.patch({
			path: `/b/${businessId}/page-config`,
			isAuthed: true,
			body: { pageConfig },
		});

		return res.body;
	}

	public async createBusiness(displayName: string): Promise<Business> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.post({
			path: "/b/",
			isAuthed: true,
			body: {
				displayName,
				createSubscription: true,
			},
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(res.body.error.message);
		}

		// todo: check 'status' in body
		return res.body;
	}

	public async setDefaultViewer(viewerId: string): Promise<Business> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.patch({
			path: `/b/${this.varius.businessId}`,
			isAuthed: true,
			body: {
				viewerId,
			},
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(res.body.error.message);
		}

		// todo: check 'status' in body
		return res.body;
	}

	public async sendBusinessInvites(invites: Invite[]): Promise<any> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.post({
			path: `/b/${this.varius.businessId}/invites`,
			isAuthed: true,
			body: {
				recipients: invites.map(({ email, roles }) => ({ email, roles })),
			},
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not send invite");
		}

		return res.body;
	}

	public async listBusinessInvites(): Promise<any> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			path: `/b/${this.varius.businessId}/invites`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not list invites");
		}

		return res.body;
	}

	public async deleteInvite(inviteId: string) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.delete({
			path: `/b/${this.varius.businessId}/invites/${inviteId} `,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not delete invite");
		}

		return res.body;
	}

	public async getDomain(type: string, value: string) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			service: RequestService.Businesses,
			path: `/b/${this.varius.businessId}/domains/${type}/${value}`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not get business domain");
		}

		return res.body;
	}

	public async listDomains(): Promise<Array<Domain>> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			service: RequestService.Businesses,
			path: `/b/${this.varius.businessId}/domains/`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not do the list of domains");
		}

		return res.body;
	}
		
	public async listViewersAvailableClients(businessId:string): Promise<Array<Client>> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			service: RequestService.Businesses,
			path: `/b/${businessId}/viewers/clients`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not do the list of available wallet viewers");
		}

		return res.body;
	}
	
	public async listDomainOptionsByType(type: string, available: boolean = false): Promise<Array<Option>> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			service: RequestService.Businesses,
			path: `/b/${this.varius.businessId}/domains?type=${type}&available=${available}`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not do the list of domains");
		}

		return res.body.map((domain: Domain) => {
			return { value: domain.value, label: domain.value }
		});
	}

	public async listWalletServices(): Promise<Array<WalletServiceResponse>> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			service: RequestService.Businesses,
			path: `/b/${this.varius.businessId}/domains/services?tags=wallet-release,wallet-feature-branch&aggregation=OR`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not do the list of domains");
		}

		return res.body.map((service: WalletService) => {
			return { value: service.id, label: service.name }
		});
	}



	public async enableDomain(type: string, value: string, email: string, rootValue: string) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.post({
			service: RequestService.Businesses,
			path: `/b/${this.varius.businessId}/domains/${type}/${rootValue}`,
			isAuthed: true,
			body: {
				value,
				email,
			},
			needErrorMessage:true
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(res.body.error.data.message);
		}

		return res.body;
	}

	public async getDefaultDomain(type: string = "root"): Promise<DefaultDomain> {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			service: RequestService.Businesses,
			path: `/b/${this.varius.businessId}/domains/default?type=${type}`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not get default root domain");
		}

		return res.body;
	}
	public async updateDomain(type: string, value: string, oldValue: string) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.patch({
			service: RequestService.Businesses,
			path: `/b/${this.varius.businessId}/domains/${type}/${oldValue}`,
			isAuthed: true,
			body: {
				value,
			},
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not enable business domain");
		}

		return res.body;
	}
	
	public async updateDomainService(type: string, value: string, serviceId) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.patch({
			service: RequestService.Businesses,
			path: `/b/${this.varius.businessId}/domains/${type}/${value}/route`,
			isAuthed: true,
			body: {
				serviceId
			},
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not enable business domain");
		}

		return res.body;
	}

	public async disableDomain(type: string, value: string) {
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.delete({
			service: RequestService.Businesses,
			path: `/b/${this.varius.businessId}/domains/${type}/${value}`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not disable business domain");
		}

		return res.body;
	}

	public async getFonts(businessId: string | undefined): Promise<any[]> {
		if (businessId) {
			const res = await this.varius.get({
				service: RequestService.Styles,
				path: `/b/${businessId}/fonts`,
				isAuthed: true,
			});

			// todo: check `status` in body
			return res.body;
		}

		throw new Error("Could not get custom fonts - missing selected business");
	}

	// V2 (contains generic entities)
	public async getBusinessV2(businessId: string): Promise<Business> {
		const res = await this.varius.get({
			path: `/b/${businessId}`,
			isAuthed: true,
		});

		return res.body;
	}

	public async updateBusinessV2(
		businessId: string,
		business: Partial<Business>
	): Promise<Business> {
		const res = await this.varius.patch({
			path: `/b/${businessId}`,
			isAuthed: true,
			body: business,
		});

		return res.body;
	}

	public async listBusinessLogs(queryString: string): Promise<any> {
		const EXPECTED_STATUS_CODE = 200;

		const res = await this.varius.get({
			service: RequestService.Businesses,
			path: `/b/${this.varius.businessId}/logs?${queryString}`,
			isAuthed: true,
		});

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error("Could not list logs");
		}



		return res.body;
	}

	public async getStudioConfig(): Promise<any> {
		const EXPECTED_STATUS_CODE = 200;
		try {
			const hostName = window.location.hostname;
			const res = await this.varius.get({
				service: RequestService.Businesses,
				path: `/studio-viewers/config?hostname=${hostName}`,
				isAuthed: true,
			});

			if (res.statusCode !== EXPECTED_STATUS_CODE) {
				throw new Error("Could not list studio config");
			}
			return res.body;
		} catch (error) {
			return null;
		}
	}
}
